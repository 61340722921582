.GuestManager-info {
  width: 70%;
  height: 100%;
  background: #B4A99F;
  float: left;
  min-height: 100vh;
  color: black;
}

.GuestManager-infobody {
  text-align: left;
  margin: 0 auto;
  max-width: 700px;
}

.GuestManager-info h3 {
  color: #443525;
}

.GuestManager-guests {
  width: 30%;
  float: left;
  overflow: scroll;
  min-height: 100vh;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 40px;
}

.GuestManager-guest {
  margin: 15px auto;
  padding: 20px;
  background: white;
  max-width: 300px;
  border-radius: 5px;
}

.GuestManager-guest input {
  margin-top: 10px;
}

.GuestManager-submit {
  cursor: pointer;
  margin: 10px auto;
  padding: 20px;
  background: white;
  max-width: 300px;
  border-radius: 5px;
  border: 3px solid #323521;
}

.GuestManager-submit:hover {
  background: #323521;
  color: white;
}

.GuestManager-eventpic {
  width: 533px;
  margin: 0 auto;
  display: block;
  max-width: 90%;
  margin-bottom: 75px;
  margin-top: 50px;
}
.GuestManager-footerpic {
  width: 100%;
}